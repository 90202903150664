import React from "react"
import { Form, withFormik, Field } from "formik"
import * as Yup from "yup"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

const BookingForm = ({
  setFieldValue,
  isSubmitting,
  values,
  errors,
  touched,
}) => (
  <Form name="booking-form" method="post" className="c-book__form">
    <fieldset className="c-book__form-fieldset">
      <legend className="c-book__form-legend c-form__legend">
        Booking Form
      </legend>
      <div className="c-book__form-section">
        <div className="c-book__form-field">
          <div className="c-form__textbox-field">
            <label htmlFor="name" className="c-form__label">
              name
            </label>
            <Field
              type="text"
              className="c-form__textbox"
              name="name"
              component="input"
              aria-label="name"
              placeholder="Full name*"
              error={touched.name && errors.name}
            />
          </div>
        </div>
        <div className="c-book__form-field">
          <div className="c-form__textbox-field">
            <label htmlFor="email" className="c-form__label">
              email
            </label>
            <Field
              id="email"
              className="c-form__textbox"
              aria-label="email"
              component="input"
              type="email"
              name="email"
              placeholder="Email*"
              error={touched.email && errors.email}
            />
          </div>
        </div>
        <div className="c-book__form-field">
          <div className="c-form__textbox-field">
            <label htmlFor="phone" className="c-form__label">
              Phone
            </label>
            <Field
              type="text"
              className="c-form__textbox"
              name="phone"
              component="input"
              aria-label="phone"
              placeholder="Telephone Number*"
              error={touched.phone && errors.phone}
            />
          </div>
        </div>
        <div className="c-book__form-field">
          <div className="c-form__textbox-field">
            <label htmlFor="postcode" className="c-form__label">
              Postcode
            </label>
            <Field
              type="text"
              className="c-form__textbox"
              name="postcode"
              component="input"
              aria-label="name"
              placeholder="Postcode*"
              error={touched.postcode && errors.postcode}
            />
          </div>
        </div>
        <div className="c-book__form-field">
          <div className="c-form__select-field">
            <label htmlFor="service" className="c-form__label">
              Treatment
            </label>
            <div className="c-selectbox">
              <Field
                id="treatment"
                aria-label="treatment"
                className="c-selectbox__select"
                component="select"
                name="treatment"
                error={touched.treatment && errors.treatment}
              >
                <option value="">Select Treatment</option>
                {values.treatmentList.map((item, index) => {
                  return item.map((item, index) => {
                    return (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    )
                  })
                })}
              </Field>
            </div>
          </div>
        </div>
        <div className="c-book__form-field">
          <div className="c-form__textbox-field">
            <label htmlFor="datetime" className="c-form__label">
              Appointment Time
            </label>
            <DatePicker
              selected={values.date}
              onChange={date => setFieldValue("date", date)}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              timeCaption="time"
              dateFormat="MMMM d, yyyy h:mm aa"
              placeholderText="Select Appointment Date"
              id="date"
              className="c-form__datetime"
              autoComplete="fresh"
              name="date"
            />
          </div>
        </div>
        <div className="c-book__form-field c-book__form-field--full-width">
          <div className="c-form__textarea-field">
            <label htmlFor="comments" className="c-form__label">
              Comments
            </label>
            <Field
              component="textarea"
              aria-label="message"
              className="c-form__textarea"
              id="message"
              rows="8"
              type="text"
              name="message"
              placeholder="Message*"
              error={touched.message && errors.message}
            />
          </div>
        </div>
        <div className="c-book__form-field c-book__form-field--full-width">
          <button
            className="c-btn c-btn--primary c-book__form-submit"
            type="submit"
            disabled={isSubmitting}
          >
            Book Now
          </button>
        </div>
      </div>
    </fieldset>
    {values.success && (
      <div className="c-book__thankyou">
        <p>Your enquiry has been successfully submitted.</p>
      </div>
    )}
    {values.nosend && (
      <div className="c-book__error">
        <p>
          OOPS, something went wrong but we know about it. please contact us via
          email or phone
        </p>
      </div>
    )}
  </Form>
)

export default withFormik({
  mapPropsToValues: props => ({
    name: "",
    email: "",
    phone: "",
    postcode: "",
    message: "",
    treatment: "",
    date: "",
    success: false,
    nosend: false,
    treatmentList: props.treatmentList,
  }),
  validationSchema: () =>
    Yup.object().shape({
      name: Yup.string().required("Full name field is required"),
      phone: Yup.string().required("Telephone Number is required"),
      postcode: Yup.string().required("Postcode is required"),
      date: Yup.string().required("Date required"),
      email: Yup.string()
        .email("Invalid email")
        .required("Email field is required"),
      message: Yup.string().required("Message field is required"),
    }),
  handleSubmit: async (
    { name, email, phone, postcode, message, treatment, date },
    { setSubmitting, resetForm, setFieldValue }
  ) => {
    try {
      const encode = data => {
        return Object.keys(data)
          .map(
            key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
          )
          .join("&")
      }
      await fetch("https://step-2-wellbeing-backend-5lwatz0iu.vercel.app/booking?no-cache=1", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          "form-name": "booking-form",
          name,
          email,
          phone,
          postcode,
          treatment,
          date,
          message,
        }),
      })
      await setSubmitting(false)
      await setFieldValue("success", true)
      await setFieldValue("nosend", false)
      setTimeout(() => resetForm(), 10000)
    } catch (err) {
      setSubmitting(false)
      setFieldValue("success", false)
      setFieldValue("nosend", true)
    }
  },
})(BookingForm)
